<template>
  <div id="error-page" class="container-xxl h-100">

    <error-block :title="$t(`SUI.error.${customError}.title`)"
                 :message="$t(`SUI.error.${customError}.message`)"
                 v-if="customError">
      <a class="link" href="/auth/XUI/logout.jsp" v-if="showLogout">{{ $t('SUI.button.logout') }}</a>
    </error-block>

    <error-block :title="$t('SUI.error.title')" :message="$t('SUI.error.generic')" v-else/>

  </div>
</template>

<script lang="ts">
import ErrorBlock from '@/components/ui/ErrorBlock.vue';
import { defineComponent } from 'vue';

const customErrors = [
  '404',
  'invalidConfirmationLink',
  'loggedInDifferentRealm',
  'alreadyLoggedIn',
  'missingOrganization',
  'inactiveOrganization'
];

export default defineComponent({
  name: 'GenericError',
  components: { ErrorBlock },
  computed: {
    customError(): string | undefined {
      // this are errors with custom messages
      const s = this.$route.params.status as string;
      if (customErrors.includes(s)) {
        return s;
      }
    },
    showLogout(): boolean {
      const s = this.$route.params.status as string;
      return s === 'loggedInDifferentRealm' || s === 'alreadyLoggedIn';
    }
  },
});
</script>

<style scoped>

</style>
