/**
 * language configuration & lazy loading
 * based on: https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
 *
 */
import { createI18n } from 'vue-i18n';
import messages_cs from './cs.json';
import messages_de from './de.json';
import messages_de_AT from './de-AT.json';
import messages_de_BE from './de-BE.json';
import messages_de_CH from './de-CH.json';
import messages_da from './da.json';
import messages_el from './el.json';
import messages_en from './en.json';
import messages_en_AU from './en-AU.json';
import messages_en_GB from './en-GB.json';
import messages_en_NZ from './en-NZ.json';
import messages_en_ZA from './en-ZA.json';
import messages_es from './es.json';
import messages_es_MX from './es-MX.json';
import messages_fi from './fi.json';
import messages_fr from './fr.json';
import messages_fr_BE from './fr-BE.json';
import messages_fr_CH from './fr-CH.json';
import messages_fr_LU from './fr-LU.json';
import messages_hu from './hu.json';
import messages_it from './it.json';
import messages_it_CH from './it-CH.json';
import messages_ja from './ja.json';
import messages_nl from './nl.json';
import messages_nl_BE from './nl-BE.json';
import messages_nb from './nb.json';
import messages_pt from './pt.json';
import messages_pt_BR from './pt-BR.json';
import messages_pl from './pl.json';
import messages_ro from './ro.json';
import messages_sv from './sv.json';
import axios from 'axios';

const messages = {
  cs: messages_cs,
  da: messages_da,
  de: messages_de,
  'de-AT': messages_de_AT,
  'de-BE': messages_de_BE,
  'de-CH': messages_de_CH,
  el: messages_el,
  en: messages_en,
  'en-AU': messages_en_AU,
  'en-GB': messages_en_GB,
  'en-NZ': messages_en_NZ,
  'en-ZA': messages_en_ZA,
  es: messages_es,
  'es-MX': messages_es_MX,
  fi: messages_fi,
  fr: messages_fr,
  'fr-BE': messages_fr_BE,
  'fr-CH': messages_fr_CH,
  'fr-LU': messages_fr_LU,
  hu: messages_hu,
  it: messages_it,
  'it-CH': messages_it_CH,
  ja: messages_ja,
  nb: messages_nb,
  nl: messages_nl,
  'nl-BE': messages_nl_BE,
  pt: messages_pt,
  'pt-BR': messages_pt_BR,
  pl: messages_pl,
  ro: messages_ro,
  sv: messages_sv,
};

type SupportedLocale = keyof typeof messages;
const supportedLocales = Object.keys(messages) as SupportedLocale[];
supportedLocales.sort((a, b) => b == a ? 0 : a < b ? 1 : -1); // sort desc
console.debug('supportedLocales (desc):', supportedLocales);

// not very useful because immediately redirected to OIDCP - look at OpenIdConnectCallback
const getInitialLocale = () => {
  let loc = new URLSearchParams(window.location.search).get('locale');
  if (!loc) initLocale(loc);
  loc = toSupportedLocale(loc, 'en');
  console.debug(`init-locale:${loc}`);
  return loc;
};

export const toSupportedLocale = (loc: string | null, def: SupportedLocale) => {
  if (!loc) return def;

  const index = supportedLocales.findIndex(locale => {
    return loc.toLowerCase()
      .startsWith(locale.toLowerCase()); // search if it starts with a supported locale
  });
  return index < 0 ? def : supportedLocales[index];
};

export const changeLocale = (locale: string | null) => {
  if (locale) {
    const lang = toSupportedLocale(locale, 'en');
    console.log(`[$changeLocale] requested:${locale} supported:${lang}`);
    i18n.global.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html')
      ?.setAttribute('lang', lang);
  }
};

/**
 * set the locale based on the 'locale' query parameter of on server locale
 */
export const initLocale = async (loc: string | null) => {
  if (!loc) {
    // get locale from server. improve by sending the locale in #url-fragment ?
    await axios.get(import.meta.env.VITE_LOGIN_PATH + '/lang', { responseType: 'text' })
      .then(resp => {
        console.debug(`[initLocale] browser-locale: ${resp.data}`);
        changeLocale(resp.data);
      })
      .catch((e) => {
        console.info('[initLocale] no locale from server', e);
      });
  }
};

export const i18n = createI18n({
  locale: getInitialLocale(), // set locale
  fallbackLocale: 'en',
  messages
});

/* independent utilities after this line ----------------------------*/
export const localizedLink = (links: Record<string, string>) => {
  return links[i18n.global.locale] ?? links.en;
};

